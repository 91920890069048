import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Icon } from "react-bulma-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import Obfuscate from "react-obfuscate"


const MessageUsLink = ({title, caption, children}) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    emails {
                        value
                    }
                }
            }
        }
    `)
    const { emails } = data.site.siteMetadata
    const [ mainEmail, ..._ ] = emails
    return (
        <Obfuscate 
            className="button is-info"
            title={caption}
            email={mainEmail.value}
            headers={{
                subject: `Вопрос с сайта по теме: '${title}'`,
            }}
            obfuscateChildren={false}
        >
            <Icon size="small">
                <FontAwesomeIcon 
                    icon={faEnvelope}
                />
            </Icon>
            <span>
                {children}
            </span>
        </Obfuscate>
    )
} 

export default MessageUsLink