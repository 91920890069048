import React from "react"
import { Section, Container, Heading, Hero, Level, Columns } from "react-bulma-components"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/Header"
import Footer from "../components/Footer"
import MainPageLink from "../components/MainPageLink"
import MessageUsLink from "../components/MessageUsLink"
import NonStretchedImage from "../components/NonStratchedImage"


const Layout = ({ title, subtitle, description, image, slug, children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)
    const { title: siteTitle } = data.site.siteMetadata
    return (
        <div className="site">
            <Helmet>
                <meta charSet="utf-8" />
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                {title ?
                    <title>{siteTitle} | {title}</title>
                    :
                    <title>{siteTitle}</title>
                }
                <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
                {process.env.YANDEX_VERIFICATION_KEY &&
                    <meta name="yandex-verification" content={process.env.YANDEX_VERIFICATION_KEY} />
                }
                {description &&
                    <meta name="description" content={description} />
                }
            </Helmet>
            <Header slug={slug} />
            {slug !== "index" &&
                <Hero renderAs="section" className="is-dark is-bold">
                    {image ?
                        <Hero.Body className="is-paddingless">
                            <Container>
                                <Columns className="is-vcentered">
                                    <Columns.Column className="is-8-tablet">
                                        <Heading style={{ margin: 15 }}>{title}</Heading>
                                        {subtitle &&
                                            <Heading
                                                renderAs="p"
                                                subtitle
                                                style={{ margin: 15 }}
                                            >
                                                {subtitle}
                                            </Heading>
                                        }
                                    </Columns.Column>
                                    <Columns.Column className="is-4-tablet is-hidden-mobile">
                                        {image &&
                                            <NonStretchedImage
                                                fluid={image}
                                                alt={title}
                                                title={title}
                                            />
                                        }
                                    </Columns.Column>
                                </Columns>
                            </Container>
                        </Hero.Body>
                        :
                        <Hero.Body>
                            <Container>
                                <Heading>{title}</Heading>
                                {subtitle &&
                                    <Heading renderAs="p" subtitle>{subtitle}</Heading>
                                }
                            </Container>
                        </Hero.Body>
                    }
                </Hero>
            }
            <Section className="site-content">
                <Container>
                    <Columns>
                        <Columns.Column className="is-10 is-offset-1">
                            {children}
                        </Columns.Column>
                    </Columns>
                    {slug !== "index" &&
                        <Columns>
                            <Columns.Column className="is-10 is-offset-1">
                                <Level renderAs="nav">
                                    <Level.Side align="left">
                                        <Level.Item>
                                            <MainPageLink />
                                        </Level.Item>
                                    </Level.Side>
                                    <Level.Side align="right">
                                        <Level.Item>
                                            <MessageUsLink
                                                title={title}
                                                caption="Если у Вас появились вопросы"
                                            >
                                                Написать нам
                                    </MessageUsLink>
                                        </Level.Item>
                                    </Level.Side>
                                </Level>
                            </Columns.Column>
                        </Columns>
                    }
                </Container>
            </Section>
            <Footer slug={slug} />
        </div>
    )
}


export default Layout
