import React from "react"
import { Link } from "gatsby"
import { Columns, Content } from "react-bulma-components"


export default ({ slug, mainMenu, servicesMenu }) =>
    <Columns>
        <Columns.Column>
            <Content>
                <ul>
                    {servicesMenu.map((item, index) =>
                        <Link
                            key={index}
                            activeClassName={
                                slug === item.href ?
                                    "is-disable" : ""
                            }
                            to={item.href}
                        >
                            <li>{item.text}</li>
                        </Link>
                    )}
                </ul>
            </Content>
        </Columns.Column>
        <Columns.Column className="is-narrow">
            <Content>
                <ul>
                    {mainMenu.map((item, index) =>
                        <Link
                            key={index}
                            activeClassName={
                                slug === item.href ?
                                    "is-disable" : ""
                            }
                            to={item.href}
                        >
                            <li>{item.text}</li>
                        </Link>
                    )}
                </ul>
            </Content>
        </Columns.Column>
    </Columns>
