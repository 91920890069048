import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Navbar } from "react-bulma-components"
import Img from "gatsby-image"


const Header = ({slug}) => {
    const [open, setOpen] = useState(false)
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title,
                        mainMenu {
                            href, text
                        }
                    }
                }
                file(
                    relativePath: { eq: "logo.png" }
                ) {
                    childImageSharp {
                        fixed(width: 210) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `
    )
    const { title, mainMenu } = data.site.siteMetadata
    const logo = data.file.childImageSharp.fixed
    return (
        <Navbar
            className="has-shadow is-light"
            active={open}
        >
            <Navbar.Brand>
                <Link 
                    className="navbar-item"
                    to="/" 
                >
                    <Img
                        alt={title}
                        fixed={logo}
                    />
                </Link>
                <Navbar.Burger
                    onClick = { () => setOpen(!open) }
                />
            </Navbar.Brand>
            <Navbar.Menu>
                <Navbar.Container>
                    {mainMenu.map((item, index) => 
                        <Link
                            key={index}
                            className="navbar-item"
                            activeClassName="is-active"
                            to={item.href}
                        >
                            {item.text}
                        </Link>
                        // <Navbar.Item 
                        //     key={index} 
                        //     href={item.href}
                        //     active={slug === item.href}  
                        // >
                        //     {item.text}
                        // </Navbar.Item>
                    )}
                </Navbar.Container>
            </Navbar.Menu>
        </Navbar>
    )
}

export default Header