import React from "react"
import { Card, Content, Icon } from "react-bulma-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const CardItem = ({icon, title, date, children}) => 
    <Card>
        <Card.Header>
            <Card.Header.Title>{title}</Card.Header.Title>
            <Card.Header.Icon>
                <Icon color="info">
                    <FontAwesomeIcon icon={icon} />
                </Icon>
            </Card.Header.Icon>
        </Card.Header>
        <Card.Content>
            <Content>
                {children}
            </Content>
            <span className="is-italic has-text-grey">
                Дата публикации: <time dateTime={date}>{date}</time>
            </span>
        </Card.Content>
    </Card>


export default CardItem