import React from "react"
import { Link } from "gatsby"
import { Columns, Breadcrumb } from "react-bulma-components"


export default ({slug, mainMenu}) =>
    <Columns className="is-centered">
        <Columns.Column className="is-narrow">
            <Breadcrumb
                hrefAttr="to"
                renderAs={({ children, ...props }) =>
                    <Link {...props}>
                        {children}
                    </Link>}
                items={mainMenu.map(({ text, href }) =>
                    ({ name: text, url: href }))}
            />
        </Columns.Column>
    </Columns>

