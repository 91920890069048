import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Container, Footer, Content } from "react-bulma-components"

import MainMenu from "../components/MainMenu"
import MainServicesMenu from "../components/MainServicesMenu"


const MyFooter = ({slug}) => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title,
                        address {
                            city, street, house, block, room
                        },
                        phones {
                            type, value
                        }
                        mainMenu {
                            href, text
                        }
                        servicesMenu {
                            href, text
                        }
                    }
                }
            }
        `
    )
    const { 
        title, 
        address, 
        phones,
        mainMenu,
        servicesMenu,
    } = data.site.siteMetadata
    const [mainPhone,] = phones
    const { city, street, house, block, room } = address
    return (
        <Footer>
            <Container>
                {slug === "index" ?
                    <MainMenu slug={slug} mainMenu={mainMenu} />
                    :
                    <MainServicesMenu slug={slug} mainMenu={mainMenu} servicesMenu={servicesMenu} />
                }
                <Content style={{ textAlign: "center" }}>
                    <p>
                        &copy; 2019
                        {" "}
                        <Link to="/">{title}</Link>
                        {" "}
                        {city}, {street}, {house}, {block}, {room}.
                        {" "}
                        <span className="has-text-weight-bold">
                            {mainPhone.type}: {mainPhone.value}
                        </span>
                    </p>
                </Content>
            </Container>
        </Footer>
    )
}


export default MyFooter